<div class="theme-static">
    <section class="header position-fixed w-100">
      <racv-app-header></racv-app-header>
    </section>

    <!-- End Header Part -->

    <!-- Start Page Header Title Part -->
    <section class="page-header-title">
      <div class="page-title-overlay"></div>
      <div class="py-5 mt-lg-5 mt-1 ">
        <!-- <img
          src="assets/images/racq/page-header/thank-you.png"
          alt=""
          class="w-100 page-title-image"
        /> -->
      </div>
      <div class="text-center">
        <h1 class="">Payment Receipt</h1>
      </div>
    </section>
    <!-- End Page Ttile  -->

    <!-- Main Content -->
    <main class="page-content py-5">
      <!-- Payment Loading Spinner -->
      <!-- <section id="payment-loading" style="height: 100vh;" >
		<div class="container mt-3 p-3 ">
			<div class="row text-center">
				<div class="col-md-12 p-4">
					<h3>Payment Gateway</h3>
					<div class="gateway-icon">
						<img src="assets/icons/racq/circle-notch.png" alt="">
					</div>
					<h4 class="fw-bold ">We are processing your payments...</h4>
					<p class="offc label mb-1">Do not close this window or refresh or click back, else your transaction may fail</p>
				</div>
			</div>						
		</div>
	</section> -->

      <section id="loading" style="height: 100vh" *ngIf="isPaymentLoading">
        <div class="container mt-3 p-3">
          <div class="row text-center">
            <div class="col-md-12 p-4">
              <h3>Payment Gateway</h3>
              <div class="gateway-icon">
                <img src="assets/icons/racq/circle-notch.png" alt="" />
              </div>
              <h4 class="fw-bold">We are processing your payments...</h4>
              <p class="offc label mb-1">
                Do not close this window or refresh or click back, else your
                transaction may fail
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="payment-success" *ngIf="!isPaymentLoading">
        <!-- <section id="payment-success"> -->
        <div class="container">
          <div *ngIf="agentGuid" class="row mb-4">
            <div class="col-12" style="text-align: right;">
              <a
                *ngIf="agentGuid"
                class="btn btn-info btn-lg"
                [href]="goAdminLink"
                target="_blank"
                ><img src="assets/icons/racq/open-link.png" alt="" width="24" />
                View Policy For Admin</a>
            </div>
          </div>

          <div class="row g-0">
            <div class="col-lg-7 pe-md-4">
              <div class="page-content-info">
                <h3
                  class="my-3"
                  style="font-weight: 900; font-size: 24px;"
                >
                  Thank you for purchasing RACQ Travel Insurance
                </h3>
                <p> 
                  Your certificate of insurance and payment receipt is provided here, and a copy has been sent to:
                </p>
                <p class="fw-bold">
                  <a
                    class="text-dark"
                    href="mailto:{{
                      travelerDetails?.contactAndAddressDetails.emailId
                    }}"
                    >{{ travelerDetails?.contactAndAddressDetails.emailId }}</a
                  >
                </p>

                <p>
                  If you have any questions about your policy, feel free to call
                  us on <a href="tel:1300 338 821">1300 338 821</a> and quote
                  your policy number. If you have a Hotmail, Bigpond, Gmail or
                  Outlook account the policy email may be blocked by your
                  firewall or email provider. Please check your spam folder and
                  contact us if you have not received your insurance
                  documentation within 24 hours.
                </p>

              </div>

            

              <div class="page-content-info">
                <h3>Emergency Assistance</h3>
                <p> 
                  In the event you need emergency assistance when travelling you can call our worldwide emergency assistance number, 24 hours a day, 7 days a week: 
                  <br>
                  <a href="tel:+61 2 8055 1696" class="fw-bold"
                  >+61 2 8055 1696</a
                >
                </p>
                 
              </div>

              <div class="page-content-info">
                <h3>Medical Conditions</h3> 
                <p>
                  There are a limited number of Medical Conditions covered under this policy. If you have a 
                  Medical Condition you must review the
                  <a href="{{ pdsLink }}" target="_blank"
                    >Combined Product Disclosure Statement and Financial Service Guide (PDS)</a> 
                  to make sure that the policy is right for you. 
                  Any specific Medical Conditions you have listed and we have offered cover for which 
                  you have accepted by paying any applicable premium, will be shown on your Certificate 
                  of Insurance. Any Medical Conditions you have listed that we have not offered cover for, 
                  or you have not accepted cover for, will also be shown on your Certificate of Insurance.
                  If you have any questions, please give us a call on 
                  <a href="tel:1300 338 821">1300 338 821</a>. 
                </p>
              </div>

              <div class="page-content-info">
                <h3>High Risk Countries</h3>
                <p>
                  This policy excludes travel to any country with the advice of "Do not travel" on the  
                  <a target="_blank" href="https://www.smartraveller.gov.au/"
                    >Smartraveller Website.</a
                  >
                </p>
              </div>
            </div>

            <!-- Aside Part / Sidebar -->
            <div class="col-lg-5">
              <div class="page-sidebar">
                <div class="p-4 bg-light rounded-10">
                  <div class="mb-3">
                    <img class="" style="max-width: 180px; margin-left: -22px;margin-bottom: 14.5px;" src="assets/images/racq/racq-logo.png" />
                    <h3 class="" style="font-weight: 700; font-size: 28px">
                      Certificate of Insurance
                    </h3>
                    
                  </div>
                  <table class="table table-borderless">
                    <tr>
                      <td>Policy Number</td>
                      <td class="fw-bold">{{ policyNumber }}</td>
                    </tr>
                    <tr>
                      <td>Policy Type</td>
                      <td>{{ quoteDetail?.productAlias }}</td>
                    </tr>
                    <tr>
                      <td>{{ quoteEnquiry?.tripType === 'Single' ? 'Period of Insurance' : 'Policy Start & End Dates' }}</td>
                      <td>
                          {{ quoteDetail?.fromDate
                          }}<span
                            ><img
                              src="assets/images/racq/chevron_right.png" /></span
                          >{{ quoteDetail?.toDate }}
                          <span
                            *ngIf="quoteDetail?.isSingleTrip"
                            class="text-gray ms-1"
                            >{{ travelDays }} days</span
                          >
                      </td>
                    </tr>
                    <tr>
                      <td>Travellers Insured</td>
                      <td>
                        <ng-container
                          *ngFor="let traveler of insuredTravelerDetails"
                        >
                          {{ traveler.name }} <br />
                          <span class="text-gray" style="padding: 0 !important;"
                            >aged {{ traveler.dateOfBirth | ageFormat }}</span
                          >
                          <br />
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td>Destination</td>
                      <td>{{ countryNames }}</td>
                    </tr>
                    <tr>
                      <td>Trip Type</td>
                      <td>{{ quoteEnquiry?.tripType === 'Single' ? 'Single Trip' : 'Annual Multi Trip' }}</td>
                    </tr>
                    <tr>
                      <td>No. Dependents</td>
                      <td>
                        {{ quoteEnquiry?.dependentsCount }}
                      </td>
                    </tr>
                    <tr>
                      <td>Cruise</td>
                      <td>
                        {{ quoteDetail?.isCruise ? 'Yes' : 'No' }}
                      </td>
                    </tr>
                    <tr>
                      <td>Ski & Winter Sports</td>
                      <td>
                        {{ quoteDetail?.isSking ? 'Yes' : 'No' }}
                      </td>
                    </tr>
                    <tr>
                      <td>Your Excess</td>
                      <td>{{ quoteDetail?.excess }}</td>
                    </tr>
                  </table>
                </div>

                <!-- Spacer -->
                <div class="my-4"></div>

              <div class="p-4 bg-light rounded-10">
                <h3 class="my-3" style="font-weight: 700; font-size: 30px">
                  Tax Invoice
                </h3>
                <p class="fw-bold text-gray">{{ formattedInvoiceDate }}</p>
                <hr />
                <table class="table table-borderless">
                  <tr>
                    <td>{{ quoteDetail?.productAlias }} Policy</td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums.product | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr *ngIf="!quoteDetail?.isSingleTrip">
                    <td>{{ quoteDetail?.multiTripLength }}
                      Max Length Trip
                    </td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums?.multiTripLength | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr>
                    <td>Cruise</td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums.cruise | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr>
                    <td>Ski & Winter Sports</td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums.skiing | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr>
                    <td>Medical Conditions</td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums.medical | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr *ngIf="quoteDetail?.excess">
                    <td>{{ quoteDetail?.excess }} Excess</td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums.excess | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr>
                    <td>Membership Discount</td>
                    <td
                      class="fw-bold"
                      *ngIf="quoteDetail?.premiums.membershipDiscount < 0"
                    >
                      -${{ -quoteDetail?.premiums.membershipDiscount | number : '1.2-2'}}
                    </td>
                    <td
                      class="fw-bold"
                      *ngIf="quoteDetail?.premiums.membershipDiscount === 0"
                    >
                      ${{ quoteDetail?.premiums.membershipDiscount | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr>
                    <td>Promo Discount</td>
                    <td
                      class="fw-bold"
                      *ngIf="quoteDetail?.premiums.promoCodeDiscount < 0"
                    >
                      -${{ -quoteDetail?.premiums.promoCodeDiscount | number : '1.2-2'}}
                    </td>
                    <td
                      class="fw-bold"
                      *ngIf="quoteDetail?.premiums.promoCodeDiscount === 0"
                    >
                      ${{ quoteDetail?.premiums.promoCodeDiscount | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td class="fw-bold">${{ quoteDetail?.premiums.gst | number : '1.2-2'}}</td>
                  </tr>
                  <tr>
                    <td>Stamp Duty</td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums.stampDuty | number : '1.2-2'}}
                    </td>
                  </tr>
                  <tr class="border-top">
                    <td>Total</td>
                    <td class="fw-bold">
                      ${{ quoteDetail?.premiums.totalCost | number : '1.2-2'}}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

    <!-- Footer Area -->
    <app-racv-footer></app-racv-footer>
</div>
